import styled from "styled-components";

import { media } from "./../../common/MediaQueries";

const BiosItemStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media ${media.tablet} {
    flex-direction: row;
  }
`;

const BiosItemMediaStyled = styled.div`
  width: 180px;
  margin-bottom: 30px;

  @media ${media.tablet} {
    margin-bottom: 0;
    width: 180px;
    height: 190px;
  }
`;

const BiosItemContentStyled = styled.div`
  width: calc(100%);

  @media ${media.tablet} {
    padding-left: 50px;
    width: calc(100% - 154px);
  }
`;

const BiosItemContentHeadStyled = styled.div`
  margin-bottom: 20px;
`;

const BiosItemContentHeadNameStyled = styled.div`
  margin-bottom: 6px;
`;

const BiosItemContentHeadTitleStyled = styled.div``;

const BiosItemContentBodyStyled = styled.div`
  *:not([class]) {
    font-size: 13px;
    font-weight: ${({ theme }) => theme.fontWeight.light};
    line-height: 1.8;
    margin-bottom: 8px;
  }
`;

export {
  BiosItemStyled,
  BiosItemMediaStyled,
  BiosItemContentStyled,
  BiosItemContentHeadStyled,
  BiosItemContentHeadNameStyled,
  BiosItemContentHeadTitleStyled,
  BiosItemContentBodyStyled,
};
