import styled, { css } from "styled-components";
import { background, color } from "styled-system";

import { customMedia, media } from "./../../common/MediaQueries";

const FormSectionStyled = styled.div`
  position: relative;
`;

const FormSectionMediaStyled = styled.div`
  margin: 0 auto 32px;

  width: 174px;
  order: 1;

  @media ${media.phone} {
    width: 332px;
  }

  @media ${media.tablet} {
    height: 400px;
    order: 2;
    margin: 0;
  }
`;

const FormSectionContentWrapperStyled = styled.div`
  ${background};
  ${color};
  order: 2;
  @media ${customMedia.maxTablet} {
    padding: 0 25px;
    margin: 0 -25px;
  }

  @media ${media.tablet} {
    width: calc(100% - 410px);
    padding-top: 26px;
    order: 1;

    ${({ isFormFullWidth }) =>
      isFormFullWidth &&
      css`
        width: 100%;
      `}
  }
`;

const FormSectionContentItemStyled = styled.div`
  margin-bottom: 20px;
  max-width: 500px;
`;

const FormSectionContentStyled = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const FormSectionContentWrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export {
  FormSectionStyled,
  FormSectionMediaStyled,
  FormSectionContentItemStyled,
  FormSectionContentWrapperStyled,
  FormSectionContentStyled,
  FormSectionContentWrapStyled,
};
