import styled, { css } from "styled-components";
import { flex, flexDirection, padding, width, flexWrap } from "styled-system";
import { media } from "../../common/MediaQueries";

const TwoColumnTextStyled = styled.div`
  display: flex;
  ${flex};
  ${flexDirection};
  ${flexWrap};
`;

interface TwoColumnTextItemStyledProps {
  cssColumns?: boolean
}

const TwoColumnTextItemStyled = styled.div<TwoColumnTextItemStyledProps>`
  display: flex;
  ${flex};
  ${padding};
  ${width};

  ${({ cssColumns }) => cssColumns && 
    css`
      padding-bottom: 40px;

      @media ${media.phone} {
        padding-bottom: 0
      }

      .content { 
        @media ${media.phone} { 
          column-count: 2; 
          column-fill: balance;
          column-gap: 40px;
        }

        @media ${media.tablet} {
          column-gap: 70px;
        }
      }
    }`
  }
`;

export { TwoColumnTextItemStyled, TwoColumnTextStyled };
