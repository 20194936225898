import React, { useState } from "react";
import { Formik, Form } from "formik";

import {
  FormWrapperStyled,
  FormHeadStyled,
  FormBodyStyled,
  FormActionStyled,
  FormColStyled,
  FormMsgStyled,
  FormGroupItemStyled,
  FormFieldItemStyled,
  FormFieldInputStyled,
  FormFieldLabelStyled,
  FormGroupStyled,
  FormSubmitStyled,
} from "./../styles";
import { Heading3, Heading10 } from "./../../Typography";
import { encode } from "./../../../common/utils";

const HireForm = () => {
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const JOBS_FORM_API = process.env.GATSBY_JOBS_FORM_API;

  return (
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
        company: "",
        website: "",
        email: "",
        content: "",
      }}
      onSubmit={(values, actions) => {
        setIsLoading(true);

        fetch(JOBS_FORM_API, {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ action: "ajax_hire_form", ...values }),
        })
          .then((response) => response.json())
          .then((response) => {
            setSuccessMsg(response.data.response_clarification);
            setIsLoading(false);
            actions.resetForm();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            actions.setSubmitting(false);
            setIsSent(true);
          });
      }}
      validate={(values) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const errors = {};

        if (!values.email || !emailRegex.test(values.email)) {
          errors.email = "Please enter a valid email address.";
        }
        if (!values.firstname) {
          errors.firstname = "This field is required.";
        }
        if (!values.lastname) {
          errors.lastname = "This field is required.";
        }
        if (!values.company) {
          errors.company = "This field is required.";
        }
        if (!values.website) {
          errors.website = "This field is required.";
        }
        if (!values.content) {
          errors.content = "This field is required.";
        }

        return errors;
      }}
    >
      {({ errors, touched }) => (
        <Form>
          {isSent ? (
            <FormMsgStyled>
              <Heading3>{successMsg}</Heading3>
            </FormMsgStyled>
          ) : (
            <FormWrapperStyled isLoading={isLoading} isDarkBg>
              <FormHeadStyled>
                <Heading10 fontWeight="700">Hire</Heading10>
              </FormHeadStyled>
              <FormBodyStyled>
                <FormColStyled>
                  <FormGroupStyled>
                    <FormGroupItemStyled>
                      <FormFieldItemStyled>
                        <input
                          type="hidden"
                          id="action"
                          name="action"
                          value="ajax_hire_form"
                        />
                        <FormFieldInputStyled
                          id="firstname"
                          name="firstname"
                          type="text"
                          required
                          className={`${
                            touched.firstname && errors.firstname
                              ? "is-invalid"
                              : touched.firstname && !errors.firstname
                              ? "is-valid"
                              : ""
                          }`}
                        />

                        <FormFieldLabelStyled htmlFor="firstname">
                          First Name
                        </FormFieldLabelStyled>
                      </FormFieldItemStyled>
                    </FormGroupItemStyled>

                    <FormGroupItemStyled>
                      <FormFieldItemStyled>
                        <FormFieldInputStyled
                          id="lastname"
                          name="lastname"
                          type="text"
                          required
                          className={`${
                            touched.lastname && errors.lastname
                              ? "is-invalid"
                              : touched.lastname && !errors.lastname
                              ? "is-valid"
                              : ""
                          }`}
                        />

                        <FormFieldLabelStyled htmlFor="lastname">
                          Last Name
                        </FormFieldLabelStyled>
                      </FormFieldItemStyled>
                    </FormGroupItemStyled>
                  </FormGroupStyled>
                  <FormGroupStyled>
                    <FormGroupItemStyled>
                      <FormFieldItemStyled>
                        <FormFieldInputStyled
                          id="company"
                          name="company"
                          type="text"
                          required
                          className={`${
                            touched.company && errors.company
                              ? "is-invalid"
                              : touched.company && !errors.company
                              ? "is-valid"
                              : ""
                          }`}
                        />
                        <FormFieldLabelStyled htmlFor="company">
                          Company
                        </FormFieldLabelStyled>
                      </FormFieldItemStyled>
                    </FormGroupItemStyled>
                    <FormGroupItemStyled>
                      <FormFieldItemStyled>
                        <FormFieldInputStyled
                          id="website"
                          name="website"
                          type="url"
                          required
                          className={`${
                            touched.website && errors.website
                              ? "is-invalid"
                              : touched.website && !errors.website
                              ? "is-valid"
                              : ""
                          }`}
                        />

                        <FormFieldLabelStyled htmlFor="website">
                          Website url
                        </FormFieldLabelStyled>
                      </FormFieldItemStyled>
                    </FormGroupItemStyled>
                  </FormGroupStyled>
                  <FormGroupStyled fullWidth>
                    <FormGroupItemStyled>
                      <FormFieldItemStyled>
                        <FormFieldInputStyled
                          id="email"
                          name="email"
                          type="email"
                          required
                          className={`${
                            touched.email && errors.email
                              ? "is-invalid"
                              : touched.email && !errors.email
                              ? "is-valid"
                              : ""
                          }`}
                        />

                        <FormFieldLabelStyled htmlFor="email">
                          Email
                        </FormFieldLabelStyled>
                      </FormFieldItemStyled>
                    </FormGroupItemStyled>
                  </FormGroupStyled>
                </FormColStyled>
                <FormColStyled>
                  <FormGroupStyled fullWidth>
                    <FormGroupItemStyled>
                      <FormFieldItemStyled isTextArea>
                        <FormFieldInputStyled
                          isTextArea
                          id="content"
                          name="content"
                          type="text"
                          required
                          component="textarea"
                          className={`${
                            touched.content && errors.content
                              ? "is-invalid"
                              : touched.content && !errors.content
                              ? "is-valid"
                              : ""
                          }`}
                        />

                        <FormFieldLabelStyled htmlFor="content">
                          How can we help you?
                        </FormFieldLabelStyled>
                      </FormFieldItemStyled>
                    </FormGroupItemStyled>
                  </FormGroupStyled>
                </FormColStyled>
              </FormBodyStyled>
              <FormActionStyled>
                <FormSubmitStyled type="submit">Send</FormSubmitStyled>
              </FormActionStyled>
            </FormWrapperStyled>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default HireForm;
