import React from "react";

import Container from "./../../components/Container";
import Section from "./../../components/Section";
import Inner from "./../../components/Inner";

import GetStartedCta from "./../../components/GetStartedCta";
import ButtonLink from "./../../components/ButtonLink";

const FlexHireCta = ({ block, index }) => {
  return (
    <Container pb={["36px", "4.5rem"]}>
      <Inner>
        <Section>
          <Inner isSmall noOffsetMobile>
            <GetStartedCta
              heading={block.heading}
              desc={block.description}
              button={
                <ButtonLink
                  as="a"
                  internal
                  blue
                  href={block.button.url}
                  target={block.button.target}
                >
                  {block.button.title}
                </ButtonLink>
              }
              image={["", block.image.imageFile]}
              alt={block.image.altText}
            />
          </Inner>
        </Section>
      </Inner>
    </Container>
  );
};

export default FlexHireCta;
